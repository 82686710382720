@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    ::-webkit-file-upload-button {
        display: none;
    }
    ::file-selector-button {
        display: none;
    }
}

@layer components {
    .sticky-border-br {
        box-shadow:
            inset 0 -1px 0 #e2e8f0,
            inset -1px 0 0 #e2e8f0;
    }

    .sticky-border-b {
        box-shadow: inset 0 -1px 0 #e2e8f0;
    }

    .sticky-border-brt {
        box-shadow:
            inset 0 -1px 0 #e2e8f0,
            inset -1px 0 0 #e2e8f0,
            inset 0 1px 0 #e2e8f0;
    }

    .sticky-border-bt {
        box-shadow:
            inset 0 -1px 0 #e2e8f0,
            inset 0 1px 0 #e2e8f0;
    }

    .tooltip-arrow,
    .tooltip-arrow::before {
        position: absolute;
        width: 8px;
        height: 8px;
        background: inherit;
    }

    .tooltip-arrow {
        visibility: hidden;
    }

    .tooltip-arrow::before {
        visibility: visible;
        content: '';
        transform: rotate(45deg);
    }

    input[type='color']::-moz-color-swatch {
        border: none;
    }

    [data-popper-placement^='top'] > .tooltip-arrow {
        bottom: -4px;
    }

    [data-popper-placement^='bottom'] > .tooltip-arrow {
        top: -4px;
    }

    [data-popper-placement^='left'] > .tooltip-arrow {
        right: -4px;
    }

    [data-popper-placement^='right'] > .tooltip-arrow {
        left: -4px;
    }

    .skeleton {
        @apply bg-gradient-to-r from-gray-200 to-gray-300 animate-pulse opacity-20;
    }
}
